import React, {useState} from "react"
import SEO from "../components/seo"
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Typography } from '@material-ui/core';
import Navbar from "../components/navbar/navbar";
import {motion} from 'framer-motion';
import ArrowLink from "../components/links/ArrowLink";

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    minHeight: '100vh',
    backgroundColor: '#192C4F',
    color: '#F3DCC6',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 132,
    paddingBottom: 132,
    [theme.breakpoints.up('xl')]: {
      paddingBottom: 1,
    },
  },
  grid: {
    width: '94%',
    [theme.breakpoints.up('md')]: {
      width: '88%',
    },
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridColumnGap: 40,
    gridRowGap: 40,
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    gridAutoRows: 150,
    [theme.breakpoints.down('md')]: {
      gridAutoRows: 300,
    }
  },
  selected: {
    height: '100%',
    border: '1px solid #D48D48',
    gridRow: '1 / 4',
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'flex-end'
  },
  arrowLink: {
    justifySelf: 'flex-end',
  },
  item: {
    border: '1px solid #D48D48',
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileCard: {
    border: '1px solid #D48D48',
    height: '100%',
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'flex-end'
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 64,
  }
}));

const services = {
  interior: {
    title: 'Interiör', 
    text: "Det är insidan som räknas, det tycker jag med. Klart det ska kännas fräscht och ljust eller murrigt och mysigt om man vil det. Ring mig så diskuterar vi vad som passar just dig."
  },
  fasad: {
    title: 'Fasad',
    text: "Jag står där i snålblåst och duggregn men jag står inte där i strålande sol för då hoppas jag att både du och jag är på berget. Att ta hänsyn till hur färg beter sig så att den inte tjocknar och blir odräglig av för mycket miljöpåverkan tycker jag är vikigt. Du kan känna dig trygg med mitt arbete - ring mig."
  },
  tapetsering: {
    title: 'Tapetsering',
    text: "En tapet säger mer än tusen penseldrag av samma kulör. Jag hjälper dig tapetsera, och tillhandahåller inspiration i form av kataloger från rekommenderande varumärken. Kontakta mig för mer info."
  },
  fargsattning: {
    title: 'Färgsättning',
    text: "Jag har koll på de senaste färgtrenderna men även på de mest tidlösa valen som håller länge. För det kommer hålla en livslängd om rätt förarbete med spackel, slipning och tvättning tas i beaktande. Jag berättar gärna mer, tryck på pilen för mina kontaktuppgifter."
  },
  renovering: {
    title: 'Renovering',
    text: "Med Rot-avdraget kan du göra avdrag från din skatt för arbetskostnaden när du anlitar mig. Det här gäller för dig som privatperson, jag berättar gärna mer. Tryck på pilen för mina kontaktuppgifter."
  },
  coaching: {
    title: 'Coachning',
    text: "Vill du måla själv men vet inte vad du behöver? Mot ersättning kommer jag hem till dig och beräknar färgåtgång samt ger tips och råd. Ring så snackas vi mer."
  },
  fresch: {
    title: 'Fräscha upp',
    text: "Behöver du förändring? Det behöver jag med, och tar därför tacksamt emot nya uppdrag. Utmana mig gärna, ring."
  },
}

const ServicesPage = () => {

  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  
  const [selectedService, setSelectedServic] = useState('interior')

 return (
  <>
     <SEO
       title="Interiör - Fasad - Tapetsering - Färgsättning - Renovering - Rot-avdrag - The Måler"
       description="Tjänster - The Måler - Åre, Järpen, Jämtland. Interiör, fasad, tapetsering. Färgsätter fasad, tak, väggar, snickerier, luckor, fönster, dörrar och möbler. Gesäll-brev. Rot-avdrag."
     />
     <Navbar page="SERVICES" />
       <div className={classes.container}>
        <div className={classes.grid}>
        {!isMobile && (
          <>
          <div className={classes.selected}>
            <div style={{flexGrow: 1}}>
              <Typography style={{paddingBottom: 32}} variant="h3">
                {services[selectedService].title}
              </Typography> 
              <Typography variant="body1">
                {services[selectedService].text}
              </Typography>
            </div>
            <div>
              <ArrowLink to="/kontakt/" label="kontakt" />
            </div>
          </div>
          {Object.keys(services).map((serviceKey, index) => {
            if(serviceKey === selectedService){
              return null
            }
            return (
              <motion.div 
                key={index}
                whileHover={{
                  scale: 1.1,
                  border: '1px solid #F3DCC6',
                }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setSelectedServic(serviceKey)} 
                className={classes.item}
                >
                <Typography variant="h3">
                  {services[serviceKey].title}
                </Typography> 
              </motion.div>
              )
            })
          }
          </>
        )}
        {isMobile && (
          <>
             {Object.keys(services).map(serviceKey => {
               return (
                 <div key={serviceKey} className={classes.mobileCard}>
                     <Typography variant="h3">
                       {services[serviceKey].title}
                     </Typography>
                    <Typography style={{paddingTop: 16}} variant="body1">
                      {services[selectedService].text}
                    </Typography>
                   </div>
               )
             })}
             <div className={classes.centerContent}>
              <ArrowLink to="/kontakt/" label="kontakt" />
             </div>
          </>
        )}
        </div>
      </div>
  </>
)
}

export default ServicesPage
