import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UnstyledLink from '../navbar/UnstyledLink';
import { Typography } from '@material-ui/core';
import ArrowRightLight from '../icons/arrowRightLight';
import { motion } from 'framer-motion';

const useStyles = makeStyles(theme => ({
  arrowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textDecoration: 'none',
    color: '#F3DCC6'
  }
}));

export default function ArrowLink({ to, label }) {
  const classes = useStyles();
  return (
    <UnstyledLink to={to}>
      <div className={classes.arrowContainer}>
        <div style={{paddingRight: 12}}>
          <Typography variant="body1">
            {label}
          </Typography>
        </div>
        <motion.div
          whileHover={{
            x: 10
          }}
          whileTap={{ scale: 0.9 }}
        >
          <ArrowRightLight size="small" />
        </motion.div>
      </div>
    </UnstyledLink>
  );
}
