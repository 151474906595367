import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  
}));

export default function UnstyledLink({ children, to }) {
  const classes = useStyles();
  return (
      <Link className={classes.link} to={to}>
        {children}
      </Link>
  );
}