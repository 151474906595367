import React from 'react';

export default function ArrowRightLight({size}) {
  
  const icon = size === 'large'?
    <svg width="188" height="179" viewBox="0 0 188 179" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M148.914 99.779H0V79.221H148.914L84.6663 14.5366L99.1048 0L188 89.5L99.1048 179L84.6663 164.463L148.914 99.779Z" fill="#F3DCC6" />
    </svg> : 
    <svg width="100" height="95" viewBox="0 0 100 95" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M79.2094 52.9553H0V42.0447H79.2094L45.0353 7.71498L52.7153 0L100 47.5L52.7153 95L45.0353 87.285L79.2094 52.9553Z" fill="#F3DCC6" />
    </svg>
      
  return icon
}
